<template>
  <div class="time-input">
    <div
      :class="[
        'time-input__control',
        { 'time-input__control--is-invalid': errorMessage },
      ]"
    >
      <input
        ref="inputElement"
        class="time-input__input"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="onInput"
      >
    </div>
    <Select
      variant="secondary"
      :model-value="period"
      :options="periodOptions"
      omit-prefix
      :disabled="disabled"
      @update:model-value="setPeriod"
    />
  </div>
</template>

<script>
import { split } from 'lodash';
import { ref, computed, nextTick } from 'vue';
import Select from '@/components/common/Select';

const getAdjustedCaretPosition = (caretPosition, value) => {
  const colonPosition = value.indexOf(':');
  if (caretPosition <= colonPosition || colonPosition === -1) return caretPosition;
  return caretPosition + 1;
};

export default {
  components: {
    Select,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '12:00',
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const inputElement = ref(null);
    const value = ref(split(props.modelValue, ' ')?.[0] || '');
    const period = ref(split(props.modelValue, ' ')?.[1] || 'AM');
    const isDirty = ref(false);

    const errorMessage = computed(() => {
      if (props.error) return props.error;
      const [hours, minutes] = value.value.split(':').map(Number);
      if ((isDirty.value && value.value.length !== 5) || (hours > 12 || minutes > 59)) return 'Invalid time format';
      return '';
    });
    const periodOptions = computed(() => [
      {
        value: 'AM',
        label: 'AM',
      },
      {
        value: 'PM',
        label: 'PM',
      },
    ]);

    const onInput = (event) => {
      isDirty.value = true;
      const caretPosition = event.target.selectionStart;

      let newValue = event.target.value.replace(/[^0-9]/g, '');
      if (newValue.length > 4) newValue = newValue.slice(0, 4);
      if (newValue.length >= 3) newValue = `${newValue.slice(0, 2)}:${newValue.slice(2)}`;

      value.value = null;
      value.value = newValue;
      emit('update:modelValue', `${newValue} ${period.value}`);

      nextTick(() => {
        const adjustedCaretPosition = getAdjustedCaretPosition(caretPosition, newValue);
        inputElement.value.setSelectionRange(adjustedCaretPosition, adjustedCaretPosition);
      });
    };
    const setPeriod = (newPeriod) => {
      period.value = newPeriod;
      emit('update:modelValue', `${value.value} ${newPeriod}`);
    };

    return {
      inputElement,
      value,
      errorMessage,
      period,
      periodOptions,
      onInput,
      setPeriod,
    };
  },
};
</script>

<style lang="scss">
.time-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &__control {
    display: flex;
    align-items: center;
    background-color: $white;
    border: $textInputBorder;
    border-radius: $textInputBorderRadius;
    width: 100%;
    height: 100%;
    transition: $textInputBoxShadowTransition;
    padding: 0 8px;

    &:not(&--is-invalid):focus-within {
      outline: 1px solid #a2bfff;
    }

    &--is-invalid {
      outline: 1px solid $error500;
    }
  }

  &__input {
    width: 100%;
    height: 100%;

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: #CDCDCD;
    }
  }
}
</style>
